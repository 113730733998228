import React from 'react'
import style from './grid.mod.scss'
import { graphql } from 'gatsby'

const Grid = ({ children, columns = '2', gridGap, scrollMobile = false }) => {
  const modifiers = [style['grid--col-' + columns]]
  gridGap && modifiers.push(style[`grid--grid-gap-${gridGap}`])
  scrollMobile && modifiers.push(style[`grid--scroll-mobile`])

  return (
    <div className={[style.grid, ...modifiers].join(' ')}>
      <div className={style.grid__wrapper}>{children}</div>
    </div>
  )
}

export default Grid

export const query = graphql`
  fragment CardGrid on DatoCmsCardGrid {
    background
    columns
    paddingTop
    paddingBottom
    cards {
      ...Card
    }
    model {
      apiKey
    }
    __typename
  }
`
