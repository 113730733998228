import React from 'react'
import { graphql } from 'gatsby'
import style from './donate.mod.scss'
import { Container } from '@components'

const Donate = () => {
  return (
    <div className={style.donate}>
      <Container maxWidth={'small'}>
        <iframe
          class="infoodle_embed_form donate_form"
          id="inf7ce73fea-8209-4a61-8a7e-bc27c39b1aff"
          name="infoodleembed_476"
          src="https://ccrt.infoodle.com/form_process?g=7ce73fea-8209-4a61-8a7e-bc27c39b1aff&amp;timestamp=1630792575722"
          frameborder="0"
          seamless="seamless"
          width="100%"
          height="1080px"
        ></iframe>
      </Container>
    </div>
  )
}

export default Donate
export const query = graphql`
  fragment Donate on DatoCmsDonate {
    id
    paddingTop
    paddingBottom
    model {
      apiKey
    }
    __typename
  }
`
