import React from 'react'
import { graphql } from 'gatsby'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import style from './carousel.mod.scss'
import { Container } from '@components'

SwiperCore.use([Navigation, Pagination])

const Carousel = ({
  children,
  id,
  heading,
  config,
  width = 'full-width',
  type = 'default',
  scaleSlides = false
}) => {
  const modifiers = []
  width &&
    modifiers.push(
      `${style.carousel}--${width
        .trim()
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  type &&
    modifiers.push(
      `${style.carousel}--${type
        .trim()
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  scaleSlides && modifiers.push(`${style.carousel}--scale`)

  const slides = children.map((slide, i) => {
    return <SwiperSlide key={`${id}-slide-${i}`}>{slide}</SwiperSlide>
  })

  return (
    <div className={[style.carousel, ...modifiers].join(' ')}>
      {heading && (
        <Container>
          <h2>{heading}</h2>
        </Container>
      )}
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        grabCursor
        navigation={true}
        {...config}
      >
        {slides}
      </Swiper>
    </div>
  )
}

export default Carousel

export const query = graphql`
  fragment Slider on DatoCmsSlider {
    id
    __typename
    model {
      apiKey
    }
    paddingTop
    paddingBottom
    width
    slides {
      id
      button {
        ...Link
      }
      heading
      contentAlignment
      contentBackground
      caption
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "720", fit: "crop", w: "1920", q: 60 }
        )
        alt
      }
      imageMobile: image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "800", fit: "crop", w: "400", q: 60 }
        )
        alt
      }
      model {
        apiKey
      }
      __typename
    }
  }
  fragment CardSlider on DatoCmsCardSlider {
    id
    paddingBottom
    paddingTop
    background
    heading
    __typename
    model {
      apiKey
    }
  }
`
