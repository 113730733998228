import React from 'react'
import { Block, ImageGrid } from '@components'

const DatoCmsImageGrid = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <ImageGrid {...props} />
    </Block>
  )
}

export default DatoCmsImageGrid
