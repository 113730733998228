import React from 'react'
import style from './intro.mod.scss'
import { Breadcrumbs, Button, Block, Container } from '@components'
import { GatsbyImage } from 'gatsby-plugin-image'

const Intro = ({ breadcrumbs, title, button, image, categories, date }) => {
  const modifiers = []
  categories && date && modifiers.push(style[`intro--type-post`])

  return (
    <div className={[style.intro, ...modifiers].join(' ')}>
      <Block gutters>
        <Container maxWidth={'large'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className={style.intro__title}>
            <h1>{title}</h1>
            {button && (
              <div className={style.intro__button}>
                <Button
                  to={button?.link?.path || button.url}
                  buttonStyle="Primary"
                  color={'inverse'}
                >
                  {button.displayText}
                </Button>
              </div>
            )}
          </div>
          {categories && date && (
            <div className={style.intro__post}>
              <p>
                <span className={style.intro__date}>{date}</span>
                {categories.map(item => (
                  <span
                    key={`category-${item.title}`}
                    className={style.intro__category}
                  >
                    {item.title}
                  </span>
                ))}
              </p>
            </div>
          )}
        </Container>
      </Block>
      {image && (
        <GatsbyImage
          className={style.intro__gatsby_image}
          image={image.gatsbyImageData}
          alt={image.alt}
          loading={'eager'}
        />
      )}
    </div>
  )
}

export default Intro
