import React from 'react'
import { graphql } from 'gatsby'
import style from './supporters-grid.mod.scss'
import { Container } from '@components'
import { GatsbyImage } from 'gatsby-plugin-image'

const SupportersGrid = ({ columns = 3, id, supporters }) => {
  const modifiers = [style['supporters-grid--columns-' + columns]]

  return (
    <div className={[style['supporters-grid'], ...modifiers].join(' ')}>
      <Container maxWidth={'large'}>
        <div className={[style['supporters-grid__grid']]}>
          {supporters.map((item, i) => {
            return (
              <Link link={item.link}>
                <div
                  key={`supporters-grid-${id}-${item.id}-${i}`}
                  className={[style['supporters-grid__item']]}
                >
                  <GatsbyImage
                    className={style.intro__gatsby_image}
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt}
                  />
                  {item.name && <h5>{item.name}</h5>}
                </div>
              </Link>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

const Link = ({ link, children }) => {
  if (link) {
    return (
      <a
        className={[style['supporters-grid__link']]}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return <div>{children}</div>
  }
}

export default SupportersGrid

export const query = graphql`
  fragment SupportersGrid on DatoCmsSupportersGrid {
    id
    paddingTop
    paddingBottom
    background
    supporters {
      id
      name
      link
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "250", fit: "crop", w: "500", q: 60 }
        )
        alt
      }
    }
    model {
      apiKey
    }
    __typename
  }
`
