import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './video.mod.scss'
import { Modal } from '@components'

const Video = ({
  elevated = false,
  modifiers = [],
  thumbnail,
  width = 'large',
  video
}) => {
  elevated && modifiers.push(style[`video--elevated`])
  width &&
    modifiers.push(
      style[
        `video--width-${width
          .trim()
          .toLowerCase()
          .replace(/ /gi, '-')}`
      ]
    )

  const { gatsbyImageData, alt } = thumbnail

  return (
    <div className={[style.video, ...modifiers].join(' ')}>
      <div className={style.video__wrap}>
        <GatsbyImage
          className={style.video__thumbnail}
          image={gatsbyImageData}
          alt={alt}
        />
        <Modal video={video} />
      </div>
    </div>
  )
}

export default Video

export const query = graphql`
  fragment Video on DatoCmsVideo {
    background
    id
    paddingBottom
    paddingTop
    model {
      apiKey
    }
    __typename
    thumbnail: overlayImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { fit: "crop", h: "550", w: "970", q: 60 }
      )
      alt
    }
    width
    video: url {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }
`
