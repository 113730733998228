import React from 'react'
import { graphql } from 'gatsby'
import style from './people-grid.mod.scss'
import { Container, FeatureItem } from '@components'

const PeopleGrid = ({ people, id }) => {
  return (
    <div className={[style['people-grid']]}>
      <Container maxWidth={'large'}>
        <div className={[style['people-grid__grid']]}>
          {people.map((person, i) => {
            return (
              <div
                key={`image-grid-${id}-${person.id}-${i}`}
                className={[style['people-grid__item']]}
              >
                <FeatureItem
                  image={person.image}
                  heading={person.name}
                  text={person.description}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default PeopleGrid

export const query = graphql`
  fragment PeopleGrid on DatoCmsPeopleGrid {
    id
    background
    paddingTop
    paddingBottom
    model {
      apiKey
    }
    __typename
    people {
      id
      name
      description
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { h: "170", fit: "crop", w: "170", q: 60 }
        )
        alt
      }
    }
  }
`
