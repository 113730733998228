import React from 'react'
import style from './banner.mod.scss'
import { GatsbyImage, withArtDirection, getImage } from 'gatsby-plugin-image'
import { Button, Container, Content } from '@components'
import { graphql } from 'gatsby'

const Banner = ({
  image,
  cropped,
  imageMobile,
  croppedImage,
  croppedImageMobile,
  heading = null,
  link,
  caption,
  contentBackground = false,
  contentAlignment = 'centre',
  button
}) => {
  const modifiers = []

  contentBackground && modifiers.push(style[`banner--content-background`])
  cropped && modifiers.push(style[`banner--cropped`])
  modifiers.push(
    style[
      `banner--content-alignment-` +
        contentAlignment.toLowerCase().replace(/ /gi, '-')
    ]
  )

  const images = withArtDirection(getImage(cropped ? croppedImage : image), [
    {
      media: '(max-width: 700px)',
      image: getImage(cropped ? croppedImageMobile : imageMobile)
    }
  ])

  return (
    <div className={[style.banner, ...modifiers].join(' ')}>
      <div className={style.banner__wrap}>
        <div className={style.banner__image}>
          <GatsbyImage
            className={style.banner__gatsby_image}
            image={images}
            alt={image.alt}
            loading={'eager'}
          />
        </div>
        {(heading || caption || link?.link?.path || link?.url) && (
          <div className={style.banner__content}>
            <div>
              <Container maxWidth={cropped ? 'medium' : 'small'}>
                <div className={style.banner__background}>
                  {heading && <h1 className={style.heading}>{heading}</h1>}
                  {caption && !cropped && (
                    <Content
                      className={style.banner__caption}
                      content={caption}
                    />
                  )}
                  {(button?.link?.path || button?.url) && (
                    <Button
                      alignment={
                        contentAlignment.toLowerCase() === 'centre'
                          ? 'center'
                          : 'left'
                      }
                      to={button?.link?.path || button?.url}
                      children={button.displayText}
                    />
                  )}
                </div>
              </Container>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Banner

export const query = graphql`
  fragment Banner on DatoCmsBanner {
    id
    button {
      ...Link
    }
    heading
    contentAlignment
    contentBackground
    caption
    paddingTop
    paddingBottom
    cropped
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "720", fit: "crop", w: "1920", q: 60 }
      )
      alt
    }
    croppedImage: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "300", fit: "crop", w: "1920", q: 60 }
      )
      alt
    }
    imageMobile: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "800", fit: "crop", w: "400", q: 60 }
      )
      alt
    }
    croppedImageMobile: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "400", fit: "crop", w: "400", q: 60 }
      )
      alt
    }
    model {
      apiKey
    }
    __typename
  }
`
