import React from 'react'
import { Block, Donate } from '@components'

const DatoCmsDonate = ({ paddingTop, paddingBottom, ...props }) => {
  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Donate {...props} />
    </Block>
  )
}

export default DatoCmsDonate
