import React from 'react'
import style from './filter.mod.scss'
import { Select } from '@components'

const Filter = React.forwardRef(({ filters = [], onChange, location }, ref) => {
  return (
    <div className={style.filter}>
      {filters.length > 0 && (
        <div className={style.filter__selects}>
          {filters.map((filter, i) => {
            return (
              <Select
                {...filter}
                row={filters.length > 1}
                fullWidth={filters.length <= 1}
                key={`select--${filter.name}-${i}`}
                onChange={onChange}
                location={location}
                ref={ref}
              />
            )
          })}
        </div>
      )}
    </div>
  )
})

export default Filter
