import React from 'react'
import { graphql } from 'gatsby'
import style from './image-grid.mod.scss'
import { Container } from '@components'
import { GatsbyImage } from 'gatsby-plugin-image'

const ImageGrid = ({ columns = 2, imagesTwoCol, imagesThreeCol, id }) => {
  const images = columns === '3' ? imagesThreeCol : imagesTwoCol

  const modifiers = [style['image-grid--columns-' + columns]]

  return (
    <div className={[style['image-grid'], ...modifiers].join(' ')}>
      <Container maxWidth={'large'}>
        <div className={[style['image-grid__grid']]}>
          {images.map((image, i) => {
            return (
              <div
                key={`image-grid-${id}-${image.originalId}-${i}`}
                className={[style['image-grid__item']]}
              >
                <GatsbyImage
                  className={style.intro__gatsby_image}
                  image={image.gatsbyImageData}
                  alt={image.alt}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default ImageGrid

export const query = graphql`
  fragment ImageGrid on DatoCmsImageGrid {
    id
    paddingTop
    paddingBottom
    background
    columns
    imagesTwoCol: images {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "400", fit: "crop", w: "570", q: 60 }
      )
      alt
    }
    imagesThreeCol: images {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "250", fit: "crop", w: "500", q: 60 }
      )
      alt
    }
    model {
      apiKey
    }
    __typename
  }
`
