import React from 'react'
import { Block, Carousel, Card } from '@components'
import { useStaticQuery, graphql } from 'gatsby'

const DatoCmsCardSlider = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  const getAllPosts = useStaticQuery(graphql`
    {
      allDatoCmsPost(
        filter: {}
        limit: 6
        sort: { order: DESC, fields: meta___firstPublishedAt }
      ) {
        nodes {
          id
          originalId
          slug
          title
          path
          categories {
            id
            slug
            title
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "380", w: "330", fit: "crop", q: 60 }
            )
            alt
          }
          excerpt
          categories {
            title
          }
          meta {
            firstPublishedAt(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  `)

  const allPosts = getAllPosts.allDatoCmsPost.nodes

  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Carousel
        type="padding-bottom"
        scaleSlides
        config={{
          centeredSlides: true,
          spaceBetween: 18,
          navigation: false,
          slidesPerView: 2,
          initialSlide: 1,
          breakpoints: {
            320: {
              slidesPerView: 1.15,
              spaceBetween: 30
            },
            800: {
              slidesPerView: 2.5
            }
          }
        }}
        {...props}
      >
        {allPosts.map((slide, index) => {
          return (
            <Card
              horizontal
              contained
              elevated
              key={`card-slide-${props.id}-${slide.id}`}
              heading={slide.title}
              horizontalImage={slide.image}
              button={{
                link: { path: slide.path },
                displayText: 'Read More'
              }}
              description={slide.excerpt}
              subHeading={slide.meta.firstPublishedAt}
              unstyledButton={true}
              buttonStyle={'primary'}
              categories={slide.categories}
            />
          )
        })}
      </Carousel>
    </Block>
  )
}

export default DatoCmsCardSlider
