import React from 'react'
import { Block, Card, Grid } from '@components'

const DatoCmsCardGrid = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  cards,
  columns
}) => {
  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Grid columns={columns} scrollMobile>
        {cards.map(card => {
          return (
            <Card
              {...card}
              key={`card-grid-card-${card.id}`}
              elevated
              contained
            />
          )
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsCardGrid
