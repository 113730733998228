import React from 'react'
import { graphql } from 'gatsby'
import style from './content.mod.scss'
import { Button } from '@components'

const Content = ({
  content,
  link,
  linkAlignment,
  moduleStyle,
  modifiers = [],
  addLastMargin = false
}) => {
  moduleStyle &&
    modifiers.push(
      `${style.content}--${moduleStyle.toLowerCase().replace(/ /gi, '-')}`
    )
  linkAlignment &&
    modifiers.push(
      `${style.content}--link-align-${linkAlignment
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )

  addLastMargin && modifiers.push(`${style.content}--add-last-margin`)

  return (
    <div className={[style.content, ...modifiers].join(' ')}>
      <div className={style.content__wrapper}>
        <div
          className={style.content__body}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {(link?.link?.path || link?.url) && (
        <div className={style.content__button}>
          <Button centered to={link?.link?.path || link?.url}>
            {link.displayText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Content

export const query = graphql`
  fragment Content on DatoCmsContent {
    id
    background
    backgroundStyle
    paddingTop
    paddingBottom
    moduleStyle
    content
    model {
      apiKey
    }
    __typename
  }
`
