import React from 'react'
import style from './textarea.mod.scss'

const Textarea = ({ label, name, placeholder, required }) => {
  const textareaConfig = {
    id: `input--${name}`,
    name,
    placeholder,
    required
  }

  return (
    <div className={style.textarea}>
      {label && (
        <label htmlFor={`input--${name}`}>
          {label}
          {required && <span aria-label="required">*</span>}
        </label>
      )}
      <textarea {...textareaConfig} />
    </div>
  )
}

export default Textarea
