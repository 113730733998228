import React from 'react'
import { graphql } from 'gatsby'
import style from './image-with-content.mod.scss'
import { Button, Content, Image } from '@components'

const ImageWithContent = ({
  button,
  content,
  elevated,
  fullWidthImage,
  image,
  imagePosition,
  imagePositionMobile,
  width,
  modifiers = [],
  background
}) => {
  imagePosition &&
    modifiers.push(
      `${style['image-with-content']}--${imagePosition
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  imagePositionMobile &&
    modifiers.push(
      `${
        style['image-with-content']
      }--mobile-image-position-${imagePositionMobile
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  width &&
    modifiers.push(
      `${style['image-with-content']}--${width
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )

  const gatsbyImage = width === 'Content Width' ? image : fullWidthImage

  return (
    <div className={[style[`image-with-content`], ...modifiers].join(' ')}>
      <div className={style[`image-with-content__image`]}>
        <Image image={gatsbyImage} elevated={elevated} />
      </div>
      <div className={style[`image-with-content__content`]}>
        <Content linkAlignment="left" content={content} />
        {(button?.link?.path || button?.url) && (
          <Button
            to={button?.link?.path || button.url}
            children={button?.displayText}
            color={background === 'Dark' ? 'inverse' : 'default'}
          />
        )}
      </div>
    </div>
  )
}

export default ImageWithContent

export const query = graphql`
  fragment ImageWithContent on DatoCmsImageWithContent {
    background
    button {
      ...Link
    }
    content
    fullWidthImage: image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "820", fit: "crop", w: "960", q: 60 }
      )
      alt
    }
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "400", fit: "crop", w: "570", q: 60 }
      )
      alt
    }
    imagePosition
    imagePositionMobile
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    width
    __typename
  }
`
