import React from 'react'
import { Block, Content } from '@components'

const DatoCmsContent = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Content {...props} />
    </Block>
  )
}

export default DatoCmsContent
