import React from 'react'
import { graphql } from 'gatsby'
import style from './cta.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from '@components'

const Cta = ({ ctaItem, modifiers = [] }) => {
  const { heading, image, link } = ctaItem
  const { gatsbyImageData, alt } = image

  return (
    <div className={[style.cta, ...modifiers].join(' ')}>
      <div className={style.cta__wrap}>
        <div className={style.cta__image}>
          <GatsbyImage image={gatsbyImageData} alt={alt} />
        </div>
        <div className={style.cta__content}>
          <h2>{heading}</h2>
          <Button to={link?.link?.path || link?.url}>{link.displayText}</Button>
        </div>
      </div>
    </div>
  )
}

export default Cta
export const query = graphql`
  fragment Cta on DatoCmsCta {
    id
    paddingTop
    paddingBottom
    background
    model {
      apiKey
    }
    ctaItem: item {
      ...CtaItem
    }
    __typename
  }

  fragment CtaItem on DatoCmsCtaItem {
    id
    heading
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "550", fit: "crop", w: "1920", q: 60 }
      )
      alt
    }
    link {
      ...Link
    }
    __typename
  }
`
