import React, { useContext, useEffect } from 'react'
import style from './layout.mod.scss'
import PropTypes from 'prop-types'
import { JsonLd } from 'react-schemaorg'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { useStaticQuery, graphql } from 'gatsby'
import Headroom from 'react-headroom'
import { Header, Footer, Drilldown, NotificationBar } from '@components'
import ThemeContext from '@context/themeContext'
import Cathedral from '@svgs/cathedral.svg'
import Helmet from 'react-helmet'

const Layout = ({ children, pageType = '' }) => {
  const { site, allSitePage, datoCmsGlobal } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          lang
          siteUrl
        }
      }

      datoCmsGlobal {
        notificationBar {
          id
          content
          button {
            ...Link
          }
        }
      }

      allSitePage {
        nodes {
          ...MenuHelpersAllPages
        }
      }
    }
  `)

  const { isSidebarOpen, closeSidebar } = useContext(ThemeContext)

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    email: site.siteMetadata.email,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        email: site.siteMetadata.email,
        contactType: 'customer service'
      }
    ],
    url: site.siteMetadata.siteUrl,
    logo: site.siteMetadata.logo,
    sameAs: [site.siteMetadata.facebook, site.siteMetadata.instagram]
  }

  const menuTreeFromPages = nodes => {
    const allMenuItems = nodes
      .filter(node => node.context !== null && node.context.showPage)
      .map(node => {
        return { path: node.path, ...node.context }
      })

    const datoPages = allMenuItems
      .filter(item => item.hasParent === false && item.type === 'DatoCmsPage')
      .sort((a, b) => a.position - b.position)

    datoPages.forEach(parent => {
      const { treeChildren, path } = parent
      if (treeChildren.length > 0) {
        parent.treeChildren = treeChildren
          .filter(child => child.showPage)
          .map(child => {
            return {
              ...child,
              path: `${path}${child.slug}/`
            }
          })
          .sort((a, b) => a.position - b.position)
      }
    })
    return [...datoPages]
  }

  const menuItems = menuTreeFromPages(allSitePage.nodes)

  return (
    <>
      <HelmetDatoCms defer={false}>
        <meta name="format-detection" content="telephone=no" />
        <html lang="en" />
      </HelmetDatoCms>
      <div id="layout" className={style.layout}>
        <Headroom disableInlineStyles>
          {datoCmsGlobal?.notificationBar && (
            <NotificationBar {...datoCmsGlobal.notificationBar} />
          )}
          <Header menuItems={menuItems} lowOpacity={pageType === 'index'} />
        </Headroom>
        <div className={style.layout__content}>
          <main>{children}</main>
          {/* <div className={style.layout__cathedral}>
            <Cathedral />
          </div> */}
        </div>
        <Footer />
        {isSidebarOpen && (
          <div className={style.layout__sidebar}>
            <Drilldown
              isVisible={isSidebarOpen}
              items={menuItems}
              closeSidebar={closeSidebar}
            />
          </div>
        )}
        <JsonLd item={schema} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
