import React from 'react'
import style from './input.mod.scss'

const Input = React.forwardRef(
  (
    {
      label,
      name,
      type = 'text',
      placeholder,
      ErrorMessage,
      required,
      pattern,
      value,
      fullWidth,
      disabled = false
    },
    ref
  ) => {
    const modifiers = []
    ErrorMessage && modifiers.push(style['input--error'])
    fullWidth && modifiers.push(style['input--full-width'])

    const inputConfig = {
      id: `input--${name}`,
      type,
      name,
      ref,
      pattern,
      placeholder,
      required,
      value
    }

    return (
      <div className={[style.input, ...modifiers].join(' ')}>
        {label && (
          <label htmlFor={`input--${name}`}>
            {label}
            {required && <span aria-label="required">{`*`}</span>}
          </label>
        )}
        <input {...inputConfig} disabled={disabled} />
        {ErrorMessage && (
          <div className={style.input__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    )
  }
)

export default Input
