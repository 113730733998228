import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import style from './footer.mod.scss'
import { Block, Button, Container, Input } from '@components'
import Facebook from '@svgs/facebook.svg'
import Instagram from '@svgs/instagram.svg'
import LinkedIn from '@svgs/linkedin.svg'
import YouTube from '@svgs/youtube.svg'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Footer = () => {
  const classes = [style.footer]
  const { datoCmsGlobal } = useStaticQuery(graphql`
    {
      datoCmsGlobal {
        menuLinks: footerLinks {
          ...Link
        }
        facebook
        instagram
        linkedin
        youtube
      }
    }
  `)
  const {
    menuLinks = [],
    facebook,
    instagram,
    linkedin,
    youtube
  } = datoCmsGlobal

  const socialMediaLinks = []
  linkedin &&
    socialMediaLinks.push({
      url: linkedin,
      id: 'linkedin',
      children: <LinkedIn />
    })
  instagram &&
    socialMediaLinks.push({
      url: instagram,
      id: 'instagram',
      children: <Instagram />
    })
  facebook &&
    socialMediaLinks.push({
      url: facebook,
      id: 'facebook',
      children: <Facebook />
    })
  youtube &&
    socialMediaLinks.push({
      url: youtube,
      id: 'youtube',
      children: <YouTube />
    })

  return (
    <footer className={classes.join(' ')}>
      <Container maxWidth={'large'}>
        <Block gutters>
          <div className={style.footer__upper}>
            <FooterSignUp />
            {socialMediaLinks.length > 0 && (
              <FooterSocialMedia socials={socialMediaLinks} />
            )}
          </div>
        </Block>
      </Container>
      {menuLinks.length > 0 && <FooterNav links={menuLinks} />}
      <FooterMeta />
    </footer>
  )
}

const FooterSignUp = () => {
  const [formState, setFormState] = useState('ready')
  const [formResponse, setFormResponse] = useState('ready')

  const handleSubmit = e => {
    e.preventDefault()
    setFormState('loading')
    const email = e.target.EMAIL.value

    addToMailchimp(email).then(data => {
      setFormResponse(data)
      setFormState('success')
    })
  }
  return (
    <div className={style['footer__sign-up']}>
      <h4>{'Sign up to our newsletter'}</h4>
      <form
        id="newsletterForm"
        name={'Sign Up'}
        method="POST"
        onSubmit={handleSubmit}
      >
        {formState === ('ready' || 'loading') ? (
          <>
            <Input
              type="email"
              label="Email Address"
              placeholder="Enter your email address"
              name="EMAIL"
              required
            />

            <Button type={'submit'} color={'inverse'} length={'large'}>
              {formState === 'ready' && 'Sign Up'}
              {formState === 'loading' && 'Loading'}
            </Button>
          </>
        ) : (
          <p>{formResponse.msg}</p>
        )}
      </form>
    </div>
  )
}

const FooterSocialMedia = ({ socials = [] }) => {
  return (
    <div className={style['footer__social-media']}>
      <h4>{'Christ Church Cathedral. Our Cathedral'}</h4>
      <ul>
        {socials.map(item => (
          <li key={item.id}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.children}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

const FooterNav = ({ links = [] }) => {
  return (
    <div className={style.footer__nav}>
      <Container maxWidth={'large'}>
        <Block gutters>
          <nav>
            <ul>
              {links.map(item => (
                <li key={item.id}>
                  <Button
                    to={item.link?.path || item.url}
                    children={item.displayText}
                    unstyled
                  />
                </li>
              ))}
            </ul>
          </nav>
        </Block>
      </Container>
    </div>
  )
}

const FooterMeta = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <div className={style.footer__meta}>
      <Container maxWidth={'large'}>
        <Block gutters>
          <div className={style.footer__inner}>
            <p className={style.footer__copyright}>{`© ${year} Reinstate`}</p>
            <p>
              {`Powered by`}{' '}
              <Button
                unstyled
                to={'https://www.harveycameron.nz/'}
                children={'Harvey Cameron'}
              />
            </p>
          </div>
        </Block>
      </Container>
    </div>
  )
}

export default Footer
