import React from 'react'
import style from './notification-bar.mod.scss'
import { Button } from '@components'

const NotificationBar = ({ button, content, modifiers = [] }) => {
  return (
    <div className={[style[`notification-bar`], ...modifiers].join(' ')}>
      <div className={style[`notification-bar__inner`]}>
        <div
          className={style[`notification-bar__content`]}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {(button?.link?.path || button?.url) && (
          <div className={style[`notification-bar__button`]}>
            <Button
              to={button?.link?.path || button?.url}
              children={button.displayText}
              unstyled
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationBar
