import React from 'react'
import { graphql } from 'gatsby'
import style from './video-with-content.mod.scss'
import { Button, Content, Video } from '@components'

const VideoWithContent = ({
  button,
  content,
  elevated,
  fullWidthThumbnail,
  thumbnail,
  video,
  videoPosition,
  modifiers = [],
  width
}) => {
  videoPosition &&
    modifiers.push(
      `${style['video-with-content']}--${videoPosition
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  width &&
    modifiers.push(
      `${style['video-with-content']}--${width
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )

  const videoThumbnail =
    width === 'Content Width' ? thumbnail : fullWidthThumbnail

  return (
    <div className={[style[`video-with-content`], ...modifiers].join(' ')}>
      <div className={style[`video-with-content__video`]}>
        <Video video={video} thumbnail={videoThumbnail} elevated={elevated} />
      </div>
      <div className={style[`video-with-content__content`]}>
        <Content linkAlignment="left" content={content} />
        {(button?.link?.path || button?.url) && (
          <Button
            to={button?.link?.path || button.url}
            children={button?.displayText}
          />
        )}
      </div>
    </div>
  )
}

export default VideoWithContent

export const query = graphql`
  fragment VideoWithContent on DatoCmsVideoWithContent {
    background
    button {
      ...Link
    }
    content
    fullWidthThumbnail: overlayImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "820", fit: "crop", w: "960", q: 60 }
      )
      alt
    }
    id
    thumbnail: overlayImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "478", fit: "crop", w: "720", q: 60 }
      )
      alt
    }
    video: videoUrl {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
    videoPosition
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    width
    __typename
  }
`
