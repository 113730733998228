import React from 'react'
import style from './menu.mod.scss'
import { Dropdown, Button } from '@components'
import { graphql, Link } from 'gatsby'

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: -1
    }
  }

  handleToggle(curIndex, wasClicked = false) {
    this.setState({
      activeIndex: curIndex
    })
  }

  handleMouseEnter(curIndex) {
    const { activeIndex } = this.state

    if (activeIndex !== curIndex) {
      this.handleToggle(curIndex, false)
    }
  }

  handleMouseLeave() {
    this.setState({
      activeIndex: -1
    })
  }

  render() {
    const { items } = this.props
    const { activeIndex } = this.state

    const transitionSettings = {
      exit: {
        length: 0.5
      },
      entry: {
        delay: 0.2
      }
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent
        ? {
            className: [style.menu__link, style['menu__link--parent']].join(' ')
          }
        : null
    }

    const linkConfig = {
      partiallyActive: true,
      className: style.menu__link,
      activeClassName: style['menu__link--current'],
      ...transitionSettings
    }

    return (
      <nav className={style.menu}>
        <ul className={style.menu__list}>
          {items.map((item, index) => {
            const { title, path, treeChildren } = item
            return (
              <li
                key={`menu__list--${index}`}
                className={style.menu__item}
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={() => this.handleMouseLeave(index)}
              >
                {treeChildren.length > 0 ? (
                  <Link
                    {...linkConfig}
                    to={path}
                    getProps={isPartiallyActive}
                    onFocus={() => this.handleToggle(index, true)}
                    aria-haspopup="true"
                    aria-controls={`menu-${index}-box`}
                    aria-expanded={index === activeIndex}
                    id={`menu-${index}-button`}
                    children={title}
                  />
                ) : (
                  <Link
                    getProps={isPartiallyActive}
                    {...linkConfig}
                    to={path}
                    children={title}
                  />
                )}
                {treeChildren.length > 0 && (
                  <Dropdown
                    index={index}
                    items={treeChildren}
                    isOpen={index === activeIndex}
                  />
                )}
              </li>
            )
          })}
        </ul>
        <div className={style.menu__button}>
          {/* TODO Link button to dontate */}
          <Button to={'/support-us/donate/'} children={'Donate Now'} />
        </div>
      </nav>
    )
  }
}

export default Menu

export const query = graphql`
  fragment MenuHelpersAllPages on SitePage {
    path
    context {
      id
      type
      position
      slug
      title
      showPage
      treeParent {
        id
        slug
        title
        showPage
      }
      hasParent
      treeChildren {
        id
        slug
        title
        position
        showPage
      }
      __typename
    }
  }
`
