import React from 'react'
import { Block, Carousel, Banner } from '@components'

const DatoCmsSlider = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Carousel {...props}>
        {props.slides.map((slide, index) => {
          return (
            <Banner key={`banner-slide-${props.id}-${slide.id}`} {...slide} />
          )
        })}
      </Carousel>
    </Block>
  )
}

export default DatoCmsSlider
