import React from 'react'
import style from './hero-video.mod.scss'
import { motion } from 'framer-motion'

const HeroVideo = ({ video, text, thumbnail }) => {
  return (
    <div className={style.hero}>
      <div className={style.hero__video}>
        <motion.video
          src={video.url}
          poster={thumbnail}
          muted
          autoPlay
          initial={{ opacity: 0.4 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 7, duration: 3 }}
        />
      </div>
      <div className={style.hero__wrapper}>
        <motion.div
          className={style.hero__text}
          dangerouslySetInnerHTML={{ __html: text }}
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 7, duration: 1.5 }}
        />
      </div>
    </div>
  )
}

export default HeroVideo
