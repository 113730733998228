import React from 'react'
import style from './header.mod.scss'
import { Menu, Logo } from '@components'
import BarsSvg from '@svgs/bars.svg'
import CloseSvg from '@svgs/close.svg'
import ThemeContext from '@context/themeContext'

const Header = ({ menuItems }) => {
  const modifiers = []

  return (
    <header className={[style.header, ...modifiers].join(' ')}>
      <div className={style.header__container}>
        <div className={style.header__wrapper}>
          <div className={style.header__logo}>
            <Logo />
          </div>
          <div className={style.header__menu}>
            <Menu items={menuItems} />
          </div>
          <div className={style.header__controls}>
            <div className={style.header__bars}>
              <ThemeContext.Consumer>
                {({ toggleSidebar, isSidebarOpen }) => {
                  return (
                    <div onClick={() => toggleSidebar()}>
                      {isSidebarOpen ? <CloseSvg /> : <BarsSvg />}
                    </div>
                  )
                }}
              </ThemeContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
