import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import style from './modal.mod.scss'
import { isBrowser } from '@context/themeContext'
import ReactDOM from 'react-dom'
import { Button } from '@components'

const windowAvailable = isBrowser()

const appRoot = windowAvailable && window.document.querySelector('#___gatsby')

const Modal = ({ video, buttonText, modifiers = [] }) => {
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div className={[style.modal, ...modifiers].join(' ')}>
      <div className={style.modal__wrap}>
        {video &&
          windowAvailable &&
          ReactDOM.createPortal(
            <ModalVideo
              isOpen={isOpen}
              channel={video.provider}
              videoId={video.providerUid}
              onClose={handleClose}
              autoplay
            />,
            appRoot
          )}
        <Button
          aria-label={`Open modal and play video: ${video.title}`}
          onClick={handleOpen}
          color={'inverse'}
          alignment={'center'}
          children={buttonText}
          type={'button'}
          length={'large'}
        />
      </div>
    </div>
  )
}

export default Modal
