import React from 'react'
import { BannerVideo, Block } from '@components'

const DatoCmsBannerVideo = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <BannerVideo {...props} />
    </Block>
  )
}

export default DatoCmsBannerVideo
