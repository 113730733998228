import React from 'react'
import style from './banner-video.mod.scss'
import { GatsbyImage, withArtDirection, getImage } from 'gatsby-plugin-image'
import { Container, Modal } from '@components'
import { graphql } from 'gatsby'

const BannerVideo = ({ image, imageMobile, heading, video, buttonText }) => {
  const images = withArtDirection(getImage(image), [
    {
      media: '(max-width: 700px)',
      image: getImage(imageMobile)
    }
  ])

  return (
    <div className={style[`banner-video`]}>
      <div className={style[`banner-video__wrap`]}>
        <div className={style[`banner-video__image`]}>
          <GatsbyImage
            className={style[`banner-video__gatsby_image`]}
            image={images}
            alt={image.alt}
            loading={'eager'}
          />
        </div>
        <div className={style[`banner-video__content`]}>
          <Container maxWidth={'medium'}>
            <h1>{heading}</h1>
            <Modal video={video} buttonText={buttonText} />
          </Container>
        </div>
      </div>
    </div>
  )
}

export default BannerVideo

export const query = graphql`
  fragment BannerVideo on DatoCmsVideoBanner {
    id
    heading
    paddingTop
    paddingBottom
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "900", fit: "crop", w: "1600", q: 60 }
      )
      alt
    }
    imageMobile: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "550", fit: "crop", w: "800", q: 60 }
      )
      alt
    }
    buttonText
    video {
      url
      provider
      providerUid
    }
    model {
      apiKey
    }
    __typename
  }
`
